import { HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ok, err, Result } from 'neverthrow';
import { HttpResult } from './type';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IHttpOptions } from './adapter';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private responseSubject = new BehaviorSubject<Result<HttpResult<any>, HttpErrorResponse>>(null);

  constructor(private http: HttpClient) { }

  async post<T>(url: string, body: any): Promise<Result<HttpResult<T>, HttpErrorResponse>> {
    try {
      const response = await this.http.post<T>(url, body, { observe: 'response' }).toPromise();
      const data = {
        data: response.body,
        status: response.status,
        headers: response.headers,
        url: response.url || url,
        method: '',
      }
      this.responseSubject.next(ok(data))
      return ok(data);
    } catch (e) {
      this.responseSubject.next(err(e))
      return err(e as HttpErrorResponse);
    }
  }

  async get<T>(url: string, options?: IHttpOptions): Promise<Result<HttpResult<T>, HttpErrorResponse>> {
    try {
      let httpParams = new HttpParams();
      if (options?.params) {
        Object.keys(options.params).forEach(key => {
          if(options.params[key]) {
            httpParams = httpParams.append(key, options.params[key]);
          }

        });
      }

      const httpOptions = {
        params: httpParams,
        headers: options?.headers as any || new HttpHeaders(),
        responseType: options?.responseType || 'json' as any,
      };

      const response = await this.http.get<T>(url, { ...httpOptions, observe: 'response', responseType: httpOptions.responseType }).toPromise();

      const data = {
        method: 'GET',
        data: response.body,
        status: response.status,
        headers: response.headers,
        url: response.url || url
      }

      this.responseSubject.next(ok(data))
      return ok(data);
    } catch (e) {
      this.responseSubject.next(err(e))
      return err(e as HttpErrorResponse);
    }
  }

  async put<T>(url: string, body: any): Promise<Result<HttpResult<T>, HttpErrorResponse>> {
    try {
      const response = await this.http.put<T>(url, body, { observe: 'response' }).toPromise();

      const data = {
        data: response.body,
        status: response.status,
        headers: response.headers,
        url: response.url || url,
        method: '',
      }

      this.responseSubject.next(ok(data))
      return ok(data);
    } catch (e) {
      this.responseSubject.next(err(e))
      return err(e as HttpErrorResponse);
    }
  }

  async patch<T>(url: string, body: any = {}): Promise<Result<HttpResult<T>, HttpErrorResponse>> {
    try {
      const response = await this.http.patch<T>(url, body, { observe: 'response' }).toPromise();

      const data = {
        data: response.body,
        status: response.status,
        headers: response.headers,
        url: response.url || url,
        method: '',
      }

      this.responseSubject.next(ok(data))
      return ok(data);
    } catch (e) {
      this.responseSubject.next(err(e))
      return err(e as HttpErrorResponse);
    }
  }

  async delete<T>(url: string, body = {}): Promise<Result<HttpResult<T>, HttpErrorResponse>> {
    const options = {
      body: body, // Pass payload as the body of the request
      observe: 'response' as 'body'
    };

    try {
      const response: any = await this.http.delete<T>(url, options).toPromise();

      const data = {
        data: response?.body,
        status: response?.status,
        headers: response?.headers,
        url: response?.url || url,
        method: '',
      }

      this.responseSubject.next(ok(data))
      return ok(data as any);
    } catch (e) {
      this.responseSubject.next(err(e))
      return err(e as HttpErrorResponse);
    }
  }

  listen() {
    return this.responseSubject.asObservable()
  }
}

export function isHttpResponse(data: any): data is HttpResponse<any> {
  return typeof data.status == 'number';
}

