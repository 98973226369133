import { Injectable } from '@angular/core';
import { z } from 'zod';
import { ValidateSchema } from 'src/app/services/decorators/validate-schema.decorator';
import { MessageRemoteDataSourceService } from '../../../../module/chat/data/repository/message/message-remote-data-source.service';
import { MessageSocketRepositoryService } from '../../../../module/chat/data/repository/message/message-live-signalr-data-source.service';
import { IMessageSocketRepository } from '../../repository/message/message-socket-repository';
import { zodSafeValidation } from 'src/app/utils/zodValidation';
import { Logger } from 'src/app/services/logger/main/service';
import { TracingType, XTracerAsync } from 'src/app/services/monitoring/opentelemetry/tracer';


const MessageUpdateInputDTOSchema = z.object({
  memberId: z.number(),
  messageId: z.string(),
  roomId: z.string(),
  message: z.string().optional().nullable(),
  requestId: z.string().optional()
})

export type MessageUpdateInput = z.infer< typeof MessageUpdateInputDTOSchema>


@Injectable({
  providedIn: 'root'
})
export class MessageUpdateUseCaseService {

  constructor(
    public repository: IMessageSocketRepository
  ) { }


  @XTracerAsync({name:'MessageUpdateUseCaseService', module:'chat',  bugPrint: true, waitNThrow: 5000})
  async execute(input: MessageUpdateInput, tracing?: TracingType) {
    console.log('MessageUpdateUseCaseService', input)
    const validation = zodSafeValidation<MessageUpdateInput>(MessageUpdateInputDTOSchema, input)

    if(validation.isOk()) {

    } else {
      tracing.hasError('failed to update message, validation failed')
      Logger.error('failed to update message, validation failed', {
        zodErrorList: validation.error.errors,
        data: input
      })
    }

    return await this.repository.updateMessage(input);
  }

}
