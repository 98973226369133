export let versionData = {
  "shortSHA": "6e84945cf",
  "SHA": "6e84945cfd926ef723519cdaafc60b3bf0913110",
  "branch": "feature/login-v2",
  "lastCommitAuthor": "'Peter Maquiran'",
  "lastCommitTime": "'Tue Jan 14 15:16:29 2025 +0100'",
  "lastCommitMessage": "fix message",
  "lastCommitNumber": "6138",
  "changeStatus": "On branch feature/login-v2\nYour branch is ahead of 'origin/feature/login-v2' by 3 commits.\n  (use \"git push\" to publish your local commits)\n\nChanges to be committed:\n  (use \"git restore --staged <file>...\" to unstage)\n\tmodified:   src/app/pages/gabinete-digital/despachos-pr/despacho-pr/despacho-pr.page.html\n\tmodified:   src/app/pages/gabinete-digital/despachos-pr/despacho-pr/despacho-pr.page.ts\n\tmodified:   src/app/shared/popover/despachos-pr-options/despachos-pr-options.page.html\n\tmodified:   src/app/shared/popover/despachos-pr-options/despachos-pr-options.page.ts\n\tmodified:   version/git-version.ts",
  "changeAuthor": "peter.maquiran"
}